import fetch from '../utils/fetch'

export function saveSetting (query) {
  return fetch({
    url: '/yuyue/formdata',
    method: 'post',
    data: query
  })
}

export function getSettingList (query) {
  return fetch({
    url: '/yuyue/formdata',
    method: 'get',
    params: query
  })
}

export function getSettingInfo (id) {
  return fetch({
    url: '/yuyue/formdata/' + id,
    method: 'get'
  })
}

export function updateSetting (query) {
  return fetch({
    url: '/yuyue/formdata',
    method: 'put',
    data: query
  })
}

export function deleteSetting (id) {
  return fetch({
    url: '/yuyue/formdata/discard/' + id,
    method: 'post'
  })
}

export function restoreSetting (id) {
  return fetch({
    url: '/yuyue/formdata/restore/' + id,
    method: 'post'
  })
}

export function saveTemplate (query) {
  return fetch({
    url: '/yuyue/formtem',
    method: 'post',
    data: query
  })
}

export function getTemplateList (query) {
  return fetch({
    url: '/yuyue/formtem',
    method: 'get',
    params: query
  })
}

export function getTemplateInfo (id) {
  return fetch({
    url: '/yuyue/formtem/' + id,
    method: 'get'
  })
}

export function updateTemplate (query) {
  return fetch({
    url: '/yuyue/formtem',
    method: 'put',
    data: query
  })
}

export function deleteTemplate (id) {
  return fetch({
    url: '/yuyue/formtem/discard/' + id,
    method: 'post'
  })
}

export function restoreTemplate (id) {
  return fetch({
    url: '/yuyue/formtem/restore/' + id,
    method: 'post'
  })
}

export function physicalFirst () {
  return fetch({
    url: '/yuyue/setting/physical',
    method: 'get'
  })
}

export function physicalNormal (query) {
  return fetch({
    url: '/yuyue/setting/physical',
    method: 'post',
    data: query
  })
}

export function allUserList (query) {
  return fetch({
    url: '/yuyue/physical/alluserlist',
    method: 'get',
    params: query
  })
}

export function allUserData (query) {
  return fetch({
    url: '/yuyue/physical/userdata',
    method: 'get',
    params: query
  })
}

export function timeData (query) {
  return fetch({
    url: '/yuyue/physical/datelist',
    method: 'get',
    params: query
  })
}

// 活动 
export function regActivityList (query) {
  return fetch({
    url: '/yuyue/yuyueActivity/list',
    method: 'get',
    params: query
  })
}

export function regActivityEasylist (query) {
  return fetch({
    url: '/yuyue/yuyueActivity/easylist',
    method: 'get',
    params: query
  })
}

export function regActivityAdd(query){
  return fetch({
    url: '/yuyue/yuyueActivity/create',
    method: 'post',
    data: query
  })
}

// 活动
export function regActivityGet (query) {
  return fetch({
    url: '/yuyue/yuyueActivity/get',
    method: 'get',
    params: query
  })
}

export function regActivityUpdate(query){
  return fetch({
    url: '/yuyue/yuyueActivity/update',
    method: 'put',
    data: query
  })
}

// 活动
export function regActivityInvalid (query) {
  return fetch({
    url: '/yuyue/yuyueActivity/invalid',
    method: 'post',
    data: query
  })
}

export function regActivityDel(query){
  return fetch({
    url: '/yuyue/yuyueActivity/del',
    method: 'post',
    data: query
  })
}

export function regActivityRecordlist (query) {
  return fetch({
    url: '/yuyue/yuyueRecord/list',
    method: 'get',
    params: query
  })
}
export function regActivityRecordinfo (query) {
  return fetch({
    url: '/yuyue/yuyueRecord/get',
    method: 'get',
    params: query
  })
}

export function recordExport(query){
  return fetch({
    url: '/yuyue/yuyueRecord/export',
    method: 'get',
    params: query
  })
}
export function registrationReview(query){
  return fetch({
    url: '/yuyue/yuyueReview',
    method: 'put',
    data: query
  })
}

//取消活动
export function regActivityCancel(query){
  return fetch({
    url: '/yuyue/yuyueActivity/cancel',
    method: 'post',
       data: query
  })
}
